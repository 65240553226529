import './App.css';
import React, { useEffect } from 'react';
import { ReactComponent as Logo } from './Arada_Logo.svg'; 
import { gsap } from "gsap";


function App() { 

  const BroAnimator = () => {
      const animList = [{
        cName:'idle',
        duration: 4
      },{
        cName:'idle5',
        duration: 10
      },{
        cName:'idle',
        duration: 4
      },{
        cName:'idle1',
        duration: 1
      },{
        cName:'idle2',
        duration: 2
      },{
        cName:'idle3',
        duration: 2
      },{
        cName:'idle3',
        duration: 2
      },{
        cName:'idle4',
        duration: 4
      },{
        cName:'idle5',
        duration: 10
      },{
        cName:'idle',
        duration: 4
      },]

      const targetNumber = Math.floor(Math. random()*animList.length)

      if(!animList[targetNumber]){
        return BroAnimator()
      }

      document.getElementById('orionIdle').className=animList[targetNumber].cName;

      setTimeout(BroAnimator, animList[targetNumber].duration*1500)

  }


  useEffect(() => {

    setTimeout(() => {


      let arrays = document.getElementById('socialLinks').children;

 

      

      gsap.to(".App-header", { background: '#f0efef' , duration:0.2}).then(() => {
        gsap.to("#text>*", { opacity: 1 , duration: 1}).then(() => {

          let targetDelay = 0
          let targetDuration =0;
            for (let i = 0; i < 5; i++) {
              targetDelay = (1+i) * 0.14;
              targetDuration = (1+i) * 0.38;
              gsap.from("#t"+i, {transform: "translate(0px, 80px)", opacity:1, ease:"bounce.inOut",    duration:targetDuration, delay:targetDelay })
              gsap.to("#t"+i, { opacity: 1, duration:targetDuration, delay:targetDelay  })
            }

          gsap.to("#arrow", {transform: "translate(0px, 0px)", opacity:1, ease:"back.out",    duration:targetDuration, delay:targetDelay }).then(() => {
            gsap.to(".App-header", {  background: '#000000' , delay:(targetDelay - 0.3) , duration:1 })
            gsap.to("#pixelLogo", { opacity: 1, delay:(targetDelay + 0.5) , duration:2 }).then(() => {
                let arrays = document.getElementById('socialLinks').children;
                const counterCC = arrays.length
                gsap.to('#socialLinks',{ marginTop: 200 , duration:3 })
                for (let i = 0; i < counterCC; i++) {
                  let targetDelay = i * 0.47;
                  gsap.to(arrays[i], { top:120, opacity: 1, duration:0.4, delay:targetDelay  })
                }
                
                gsap.to('#animationLayer', {left: '128px', duration: 3, ease:"linear"}).then(()=> {
                  document.getElementById('orionWalk').style.display = 'none';
                  document.getElementById('orionIdle').style.display = 'block';

                 
                  BroAnimator()
                })  
            }) 
          })
 
          
        })
 
       
      }) 

    }, 2000)
   
  }, []) 

  return (
    <div className="App">
      <header className="App-header">
        <Logo className='logo-w' />
        <img id="pixelLogo" src='/arada_logo_disi_100x100.png' alt="Arada Games 100x100" />
        <div id='socialLinks'>
          <a href='https://x.com/aradagames' target='_blank'>
            <img src="x.png" alt="x" />
          </a>
          <a href='https://www.instagram.com/arada_games' target='_blank'>
            <img src="instagram.png" alt="x" />
          </a>
          <a href='https://www.youtube.com/@Arada_Games' target='_blank'>
            <img src="youtube.png" alt="x" />
          </a>
          <a href='https://discord.gg/ZkWDNM5G2N' target='_blank'>
            <img src="discord.png" alt="x" />
          </a>
          <a href='https://store.steampowered.com/app/2829040/Orion_Everton_Mysteries_of_Metrosomia/' target='_blank'>
            <img src="steam.png" alt="x" />
          </a>
        </div>
        <div id="animationLayer">
          <div id='orionWalk'></div>
          <div id='orionIdle' className=''></div>
        </div>
        
      </header>
    </div>
  );
}

export default App;
